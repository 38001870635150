@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Plus+Jakarta+Sans:wght@400;500;600&display=swap');

html {
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #0f172a;
}

body {
    font-family: 'Plus Jakarta Sans', sans-serif;
    color: #0f172a;
}

#navbar ul li a.active {
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
}

.card .active {
    color: #0d9488;
}

div[role='menu'] {
    visibility: hidden;
}

div[role='menu'].visible {
    visibility: visible;
}

.auth-page{
    column-gap: 50px;
}

.or_divider{
    display: flex;
    justify-content: center;
    align-items: center;
    column-gap: 10px;
    margin: 25px 0;
}

.or_divider .dots_line {
    flex-grow: 1;
    border-bottom: 1px dashed rgba(186, 186, 186, 1);
}

.auth-page input{
    outline: none;
    font-weight: 400;
}

.auth-page input:focus{
    background-color: rgba(238, 246, 255, 1);
    border-color: rgba(96, 155, 224, 1);
}

.auth-page .login_google{
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 8px;
    width: 100%;
    background-color: rgba(238, 246, 255, 1);
    padding: 10px;
    border-radius: 8px;
    cursor: pointer;
    transition: .2s;
}

.auth-page .login_google:hover{
    background-color: rgba(96, 155, 224, 0.4);
}

.auth-page .form-block{
    border-radius: 8px;
    box-shadow: 0px 0px 0px 0px rgba(155, 155, 157, 0.1),
                -10px 0px 21px 0px rgba(155, 155, 157, 0.1),
                -39px 0px 39px 0px rgba(155, 155, 157, 0.09),
                -88px 0px 53px 0px rgba(155, 155, 157, 0.05),
                -156px 0px 63px 0px rgba(155, 155, 157, 0.01),
                -244px 0px 68px 0px rgba(155, 155, 157, 0);
}

.auth-page .form-block > div{
    padding: 40px;
}

.auth-page .agrement-checkbox{
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    justify-content: flex-end;
    column-gap: 5px;
    margin-top: 16px;
    margin-bottom: 28px;
    position: relative;
}


.auth-page .agrement-checkbox label{
    font-weight: 400;
}

.auth-page .agrement-checkbox label a{
    color: rgba(96, 155, 224, 1);
    text-decoration: underline;
}

.auth-page .agrement-checkbox input{
    width: fit-content;
    position: relative;
    top: -3px;
}

.auth-page button[type="submit"]{
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgba(12, 12, 12, 1);
    background-color: rgba(255, 206, 88, 1);
    padding: 0;
    margin: 0;
}

.auth-page button[type="submit"]:hover{
    color: #fff;
    background-color: rgba(96, 155, 224, 1);
}

.auth-page .agrement-checkbox > div{
    position: absolute;
    top: 20px;
}

.auth-page button[type="submit"] + div{
    margin-top: 15px;
}
